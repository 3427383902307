import * as API from '@/api/index'

export default {
    skinUsePeriodUnit:params =>{
        return API.POST('api/skinUsePeriodUnit/all',params)
    },
    create:params =>{
        return API.POST('api/skinUsePeriodUnit/create',params)
    }

}