<template>
  <div class="NursingCycle content_body">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="名称">
              <el-input
                @clear="handleSearch"
                v-model="Name"
                placeholder="输入单位名称搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table :data="ReturnVisitPlan" size="small" tooltip-effect="light">
        <el-table-column prop="Unit" label="单位"></el-table-column>
        <el-table-column
          prop="UnitType"
          label="单位类型"
        ></el-table-column>
        <el-table-column label="操作" width="145px" v-if="false">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="clickEditBtn(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="clickDeleteBtn(scope.row)"
              v-prevent-click
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  -->
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!--新增、编辑弹出框-->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item
            label="周期单位"
            prop="Unit"
          >
            <el-input
              v-model="ruleForm.Unit"
              placeholder="请输入周期单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位类型" prop="UnitType">
            <el-input
            type="number"
              v-model="ruleForm.UnitType"
              placeholder="请输入单位类型"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Instrument/UsePeriodUnit";
import permission from "@/components/js/permission.js";
export default {
  name: "UsePeriodUnit",
  /**  引入的组件  */
  components: {},
   beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "推荐项目");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      isDelete:true,
      Name: "",
      loading: false,
      dialogVisible: false,
      dialogtype: 1,
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      ReturnVisitPlan: [],
      ruleForm: {},
      rules: {
        Unit: [
          {
            required: true,
            message: "请输入单位",
            trigger: ["blur", "change"],
          },
        ],
        UnitType: [
          { required: true, message: "请选择单位类型", trigger: ["blur"] },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  搜索方法  */
    handleSearch() {
      var that = this;
      const { Name, Active } = that;
      var params = {
        UnitType:Name,
        PageNum: that.paginations.page,
      };
      API.skinUsePeriodUnit(params).then((res) => {
        if (res.StateCode == 200) {
          that.ReturnVisitPlan = res.List;
          that.paginations.total = res.Total;
        }
      });
    },
    /**   切换分页 */
    handleCurrentChange() {},
    /**  新增护理周期  */
    showAddDialog() {
      let that = this;
      that.dialogtype = 1;
      that.dialogVisible = true;
    },
    /**  编辑护理周期  */
    clickEditBtn(row) {
      var that = this;
      const { PeriodID, PeriodName, UsePeriod, Unit, Active } = row;
      that.ruleForm = { PeriodID, Name: PeriodName, UsePeriod, Unit, Active };
      that.dialogtype = 2;
      that.dialogVisible = true;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createCycle();
          } else {
            that.updateCycle();
          }
        }
      });
    },
    /**  清除弹窗数据  */
    closeDialog() {
      this.ruleForm = {};
      this.dialogVisible = false;
    },
    /** 新增接口  */
    createCycle() {
      var that = this;
      API.create(that.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        }
      });
    },
    /**  编辑接口  */
    updateCycle() {},
    /**   删除 */
    clickDeleteBtn() {
      let that = this;
      that
        .$confirm("是否删除回访计划?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this. handleSearch();
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "护理周期"
    );
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
